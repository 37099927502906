<template>
  <div>
    <a @click="choosed(data)" :class="{'active': activeIndex === data['title']}" >{{ data['title'] }}</a>
    <template v-if="data['chid'] && data['chid'].length > 0">
        <ul>
            <li v-for="item in data['chid']" :key="item['title']">
                <pdfdocCatalog :data="item" :activeIndex="activeIndex" @update="activeTreeIndex($event)"></pdfdocCatalog>
            </li>
        </ul>
    </template>
  </div>
</template>

<script>
export default {
name: 'pdfdocCatalog',
props: {
    data: {
        type: Object,
        default: () => {
            return {}
        }
    },
    activeIndex: {
        type: String,
        default: () => {
            return ''
        }
    },
},
methods: {
    choosed(value) {
        this.activeTreeIndex(value)
    },
    activeTreeIndex(value) {
        this.$emit('update', value)
    }
}
}
</script>

<style lang="scss" scoped>
.active {
    color: #ffffff;
    background-color: #d2333c;
}
</style>