<template>
  <div class="page">
    <div style="display: flex; justify-content: center;">
        <!-- width: 300px; -->
         <div id="toc_div" style="max-width: 400px;">
             <!-- <div class="theme">Catalog</div> -->
             <ul id="toc" style="overflow-y: auto; position: fixed; top: 110px; height: 800px; max-width: 400px;">
                <template v-if="menus.length > 0">
                    <li v-for="(item, index) in menus" :key="index">
                        <pdfdocCatalog :data="item" :activeIndex="activeIndex" @update="activeTreeIndex"></pdfdocCatalog>
                    </li>
                </template>
             </ul>
         </div>
        <ul id="imgs" ref="imgs" style="width: 1000px; height: 100%; overflow-y: auto;"></ul>
    </div>
  </div>
</template>

<script>
import pdfjsLib from 'pdfjs-dist/build/pdf'
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry'
import { TextLayerBuilder, PDFViewer } from 'pdfjs-dist/web/pdf_viewer'
pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker
import 'pdfjs-dist/web/pdf_viewer.css'
import pdfdocCatalog from './pdfdocCatalog.vue'
import {getMaterialById} from '@/api/documentation'

export default {
name: "PdfDocPageIndex",
data() {
    return {
        url: '/resource/material/HOLOWITS%20Camera%2011.2.0%20Acceptance%20Test%20Guide.pdf',
        pdf: null,
        pageInfo: {},
        menus: [],
        activeIndex: '',
        id: ''
    }
},
components: {
    pdfdocCatalog
},
async created() {
    this.id = this.$route.params.id
    console.log(this.id )
    
},
async mounted() {
    await this.getMaterialById(this.id)
    await this.init()
},
methods: {
    async getMaterialById(id) {
        const {data: {data, isSuccess}} = await getMaterialById({
            id
        })
        if (isSuccess) {
            this.url = `https://resources.holowits.com.sg/${data.viewUrl}`
            // window.open(`https://resources.holowits.com/${data.viewUrl}`)
        }
    },
    activeTreeIndex(value) {
        this.activeIndex = value.title
        let index = value.index
        let top = 0;
        for (let i = 1; i <= index; i++) {
            top += this.pageInfo[i].height
        }
        window.scrollTo({
            top: top,
            behavior: 'smooth'
        })
    },
    async init(url) {
        this.pdf = await pdfjsLib.getDocument(this.url).promise
        // console.log(this.pdf)
        const mulu = await this.pdf.getOutline()
        // console.log(mulu)
        if (mulu) {
            if (mulu.length > 0) {
                await this.createMenus(mulu)
            }
        }
        await this.render()
    },
    async createMenus(mulu) {
        // const toc = document.getElementById("toc");
        for (let item of mulu) {
            let obj = {}
            await this.getMenu(item, toc, obj)
            console.log(obj)
            this.menus.push(obj)
        }
        const {width} = toc.getBoundingClientRect()
        const toc_div = document.getElementById("toc_div");
        toc_div.style.width = `${width}px`

    },
    async getMenu(item, element = null, node) {
        // const li = document.createElement("li");
        // const div = document.createElement("div");
        // const a = document.createElement("a");
        // a.textContent = item.title;
        // a.href = "#";

        node['title'] = item.title;

        let pageIndex = await this.pdf.getPageIndex(item.dest[0])
        node['index'] = pageIndex

        // a.setAttribute('data-index', pageIndex)
        // a.addEventListener("click", (e) => {
        //     e.preventDefault()
        //     let index = e.target.dataset.index
        //     let top = 0;
        //     for (let i = 1; i <= index; i++) {
        //         top += this.pageInfo[i].height
        //     }
        //     window.scrollTo({
        //         top: top,
        //         behavior: 'smooth'
        //     })
        // })
        // div.appendChild(a)
        // li.appendChild(div)
        // element.appendChild(li)

        if (item.items.length > 0) {
            // const ul = document.createElement("ul");
            node['chid'] = []
            for (let i of item.items) {
               let obj = {}
               await this.getMenu(i, null, obj)
               node['chid'].push(obj)
            //    li.appendChild(ul)
            }
        }
    },
    async render() {
        let imgs = this.$refs['imgs']
        let pages = []
        let pageMaxHeight = 0;
        let pageMaxWidth = 0;
        for (let i = 1; i <= this.pdf.numPages; i++) {
            let page = await this.pdf.getPage(i)
            const scale = 2.0;
            const viewport = page.getViewport(scale);
            // console.log(viewport)
            const li = document.createElement('li')
            li.setAttribute('id', `canvas-${i}`)
            const canvas = document.createElement('canvas');
            canvas.setAttribute('id', `pdf-canvas-${i}`)
            const context = canvas.getContext("2d");
            canvas.height = viewport.height;
            canvas.width = viewport.width;

            this.pageInfo[i] = { height: viewport.height, width: viewport.width }

            li.appendChild(canvas)

            if (pageMaxHeight < viewport.height) {
                pageMaxHeight = viewport.height
            }
            if (pageMaxWidth < viewport.width) {
                pageMaxWidth = viewport.width + 20
            }

            let obj = {
                element: li,
                renderContext: {
                    canvasContext: context,
                    viewport: viewport,
                },
                page
            }
            pages.push(obj)
        }
        for (let item of pages) {
            // imgs.style.height = `${pageMaxHeight}px`
            imgs.style.width = `${pageMaxWidth}px`
            imgs.appendChild(item.element)
            // console.log(item.page)
            item.page.render(item.renderContext)
            // let textContent = await item.page.getTextContent()
            // console.log(textContent)
            // const textLayerDiv = document.createElement('div')
            // textLayerDiv.setAttribute('class', 'textLayer')
            // item.element.appendChild(textLayerDiv)
            // let textLayer = new TextLayerBuilder({
            //     textLayerDiv: textLayerDiv,
            //     pageIndex: item.page.pageIndex,
            //     viewport: item.renderContext.viewport
            // })
            // textLayer.setTextContent(textContent)
            // textLayer.render()
        }
    }
}
}
</script>

<style lang="scss" scoped>
    .page {
        min-height: 100vh;
        background-color: #F9F9F9;
        width: 100%;
        margin: auto;
    }
    #toc_div {
        margin-right: 20px;
    }
</style>
<style lang="scss">
    #toc {
        background-color: #ffffff;
        // height: 300px;
        li {
            div {
                padding-top: 10px;
                padding-bottom: 10px;
                padding-left: 10px;

                &:hover > a {
                    color: #ffffff;
                    background-color: #d2333c;
                }
                
                a {
                    padding: 10px 10px;
                    display: block;
                }
            }
        }
        
    }
</style>